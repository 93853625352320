import cloneDeep from 'lodash/cloneDeep.js'
import debounce from 'lodash/debounce.js'
import { type Ref } from 'vue'
import { getMilliseconds } from '@/6_shared/lib'
import type { BaseSearchFilters } from '@/6_shared/model'

export const useSearchFilters = (
  text: Ref<string>,
  filters: Ref<BaseSearchFilters>,
  isCategorySelected: Ref<boolean>,
  handler: () => void
) => {
  const MIN_SEARCH_LENGTH = 2
  const SEARCH_DEBOUNCE = getMilliseconds.inSeconds(0.5)

  /**
   * обрабатывает ввод поисковой строки
   */
  const onTextInput = debounce(function (value: string) {
    const isSmallQuery = value.length && value.length <= MIN_SEARCH_LENGTH
    const isChanged = value !== text.value

    text.value = value

    value && !isSmallQuery
      ? (filters.value.filter.textQuery = value)
      : delete filters.value.filter.textQuery
    isChanged && !isSmallQuery && handler()
  }, SEARCH_DEBOUNCE)

  /**
   * обрабатывает смену фильтров
   * @param newFilters
   */
  const onFilterChange = (newFilters: BaseSearchFilters) => {
    let clonedFilters = cloneDeep(newFilters)
    //сохраняем выбранную категорию
    if (isCategorySelected.value) {
      clonedFilters = {
        ...clonedFilters,
        filter: {
          ...clonedFilters.filter,
          categoryIds: filters.value.filter.categoryIds
        }
      }
    }

    //сохраняем введенную поисковую строку
    if (filters.value.filter.textQuery) {
      clonedFilters = {
        ...clonedFilters,
        filter: {
          ...clonedFilters.filter,
          textQuery: filters.value.filter.textQuery
        }
      }
    }

    filters.value = clonedFilters
    handler()
  }

  return {
    onTextInput,
    onFilterChange
  }
}
