<template>
  <div
    :class="[
      'relative mx-auto overflow-hidden px-0 md:box-content',
      'h-dvh',
      'md:h-[calc(100dvh_-_52px)] md:max-h-[804px] md:max-w-[1184px] md:pt-[22px]',
      'md:px-7'
    ]"
  >
    <UiButton
      v-if="isMdAndUp"
      class="!absolute right-0 top-2 w-fit"
      icon-transparent
      @click="emit('close')"
    >
      <UiIcon name="x-mark" class="size-5 text-light md:size-6" />
    </UiButton>
    <div
      ref="resultsBlock"
      class="scrollbar-hidden container-block relative h-full overflow-hidden overflow-y-scroll bg-primary md:h-[58px] md:rounded-4xl"
      @scroll="onResultsScroll"
    >
      <header class="sticky top-0 z-40 bg-primary pt-3 md:bg-transparent md:pt-0">
        <div
          v-if="isMdAndDown && selectedCategoryHeader"
          class="relative z-30 flex w-full items-center justify-between bg-primary pb-3"
        >
          <SearchResultCategoryHeader
            :value="selectedCategoryHeader"
            is-big
            open
            @toggle="
              isCompilationSelected ? toggleCompilationSelection() : toggleCategorySelection()
            "
          />
          <button v-if="smallHeader" type="button" class="pr-4" @click="smallHeader = false">
            <UiIcon name="three-dots" class="size-6 text-text-main" />
          </button>
        </div>
        <UiContainer
          :class="{ '!absolute -translate-y-full opacity-0': smallHeader }"
          class="relative z-20 flex items-center overflow-hidden bg-primary pb-3 transition-all duration-500 md:bg-transparent md:py-2"
        >
          <UiGradientBackground
            v-if="isMdAndUp"
            class="gradient !bg-primary opacity-0"
            severity="primary"
          />
          <UiButton
            v-if="isMdAndDown && !selectedCategoryHeader"
            icon-transparent
            class="flex !size-9 items-center justify-center"
            @click="emit('close')"
          >
            <UiIcon name="chevron-down" class="size-5 rotate-90" />
          </UiButton>
          <form class="input w-full">
            <UiInput
              ref="input"
              :model-value="text"
              severity="primary"
              type="search"
              maxlength="255"
              :class="{ 'md:!pl-11': !text }"
              :placeholder="isMdAndUp ? 'найти событие или площадку' : 'найти'"
              @update:model-value="onTextInput"
            >
              <template v-if="isMdAndUp && !text" #prepend>
                <UiIcon
                  name="magnifying-glass"
                  class="absolute left-6 top-0 size-3.5 h-full text-icon-tertiary"
                />
              </template>
            </UiInput>
          </form>
        </UiContainer>
        <FilterSearch
          v-if="!isVenueCategorySelected"
          :class="{ '!absolute -translate-y-full opacity-0': smallHeader }"
          :page-size="PAGE_SIZE"
          class="content bg-primary transition-all duration-500 md:translate-y-[-50vh] md:pt-5"
          :default-select="SearchSorting.popular"
          @change="onFilterChange"
        />
      </header>
      <section
        class="content z-10 -mt-1 pb-14 pt-7 md:max-h-[650px] md:translate-y-[-100vh] md:pb-0 md:pt-9"
      >
        <template v-if="loading">
          <UiLoader size="30" class="!fixed inset-0 z-30 size-full" />
          <SearchResultsCategorySkeleton v-for="skeleton in 5" :key="skeleton" class="mb-9" />
        </template>
        <template v-else>
          <template v-if="!searchIsFailed">
            <SearchRecommendations
              v-if="isCompilationShown"
              :max-items="MAX_ITEMS_MOBILE"
              :value="compilation"
              :open="isCompilationSelected"
              :loading="!compilationInitialized"
              :is-big="isCompilationBig"
              :class="{ 'opacity-15': loading }"
              @toggle="toggleCompilationSelection"
            />
            <SearchResults
              v-else-if="!filters.filter?.categoryIds && results"
              :value="results"
              :max-items-desktop="MAX_ITEMS_DESKTOP"
              :max-items-mobile="MAX_ITEMS_MOBILE"
              @toggle="toggleCategorySelection"
            />
            <ul v-else-if="searchResultsByCategory">
              <SearchResultsCategory
                :value="searchResultsByCategory"
                :open="true"
                need-empty
                :max-items-desktop="MAX_ITEMS_DESKTOP"
                :max-items-mobile="MAX_ITEMS_MOBILE"
                @toggle="toggleCategorySelection"
                @next-page="loadNextCategoryPage"
              />
              <li
                v-if="
                  searchResultsCategoryPagination &&
                  !(
                      searchResultsCategoryPagination.currentPage >=
                    searchResultsCategoryPagination.pagesCount
                    )
                "
              >
                <UiEternalLoading
                  :handler="loadNextCategoryPage"
                  :all-loaded="
                    searchResultsCategoryPagination.currentPage >=
                      searchResultsCategoryPagination.pagesCount
                  "
                />
              </li>
            </ul>
          </template>
          <template v-else>
            <div class="flex h-full flex-col justify-center text-center">
              <p class="text-sm">ой, не смогли загрузить</p>
              <UiButton text severity="primary" class="" @click="onSearch">
                попробовать еще раз
              </UiButton>
            </div>
          </template>
        </template>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import isEqual from 'lodash/isEqual'
import throttle from 'lodash/throttle.js'
import { storeToRefs } from 'pinia'
import {
  computed,
  defineAsyncComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  type ShallowRef,
  useTemplateRef
} from 'vue'
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import { FilterSearch } from '@/4_features/Search'
import {
  SearchResultCategoryHeader,
  useSearchRecommendationCompilation,
  useSearch,
  useSearchFilters,
  useSearchCategory
} from '@/5_entities/Search'
import { AbortError } from '@/6_shared/api'
import { useBreakpoint } from '@/6_shared/lib'
import { type BaseSearchFilters, SearchSorting } from '@/6_shared/model'
import {
  UiButton,
  UiContainer,
  UiEternalLoading,
  UiGradientBackground,
  UiIcon,
  UiInput,
  UiLoader
} from '@/6_shared/ui'
import SearchRecommendations from './SearchRecommendations.vue'

type EmitType = {
  (e: 'close'): void
}
const emit = defineEmits<EmitType>()

const SearchResults = defineAsyncComponent(() => import('./SearchResults.vue'))
const SearchResultsCategory = defineAsyncComponent(() => import('./SearchResultsCategory.vue'))
const SearchResultsCategorySkeleton = defineAsyncComponent(
  () => import('./Skeleton/SearchResultsCategorySkeleton.vue')
)
//vue helper
const {
  md: [isMdAndUp, isMdAndDown]
} = useBreakpoint()
// stores
const { search, resetSearchResultsByCategory, resetSearch } = useSearch()
const { results, searchResultsByCategory } = storeToRefs(useSearch())

//constants
const PAGE_SIZE = 15
const MAX_ITEMS_DESKTOP = 5
const MAX_ITEMS_MOBILE = 8

//refs
const text = ref('')
const filters = ref<BaseSearchFilters>({
  pagination: { pageSize: PAGE_SIZE, currentPage: 1 },
  filter: {}
})
const loading = ref(false)
const searchIsFailed = ref(false)

// main methods
/**
 * оборачивает поисковые запросы в обработчик ошибок
 * @param handler
 * @param needLoading
 */
const searchWrapper = async (handler: () => Promise<void>, needLoading: boolean = true) => {
  try {
    searchIsFailed.value = false
    needLoading && (loading.value = true)
    needLoading && scrollToTop()

    await handler()
  } catch (e) {
    if (e instanceof AbortError) return
    searchIsFailed.value = true
  } finally {
    loading.value = false
  }
}
/**
 * запускает поиск
 */
const onSearch = async () => {
  searchWrapper(async () => {
    if (isCompilationShown.value) await searchInCompilation()
    else isCategorySelected.value ? await searchInCategory() : await search(filters.value)
  })
}
/**
 * скроллит поиск наверх
 */
const scrollToTop = () => {
  if (!resultsBlock.value) return

  resultsBlock.value.scrollTop = 0
}

// category
const {
  isCategorySelected,
  isVenueCategorySelected,
  searchResultsCategoryPagination,
  searchInCategory,
  loadNextCategoryPage,
  toggleCategorySelection
} = useSearchCategory(filters, searchWrapper, onSearch)

//filters
const { onTextInput, onFilterChange } = useSearchFilters(
  text,
  filters,
  isCategorySelected,
  onSearch
)

// animation
const input = useTemplateRef<typeof UiInput>('input')
const focusOnInput = (input: Readonly<ShallowRef<typeof UiInput | null>>) => {
  input.value?.inputElement.focus()
}
onMounted(() => {
  setTimeout(() => focusOnInput(input))
})

// compilation
const {
  compilation,
  compilationInitialized,
  isCompilationSelected,
  isCompilationBig,
  isCompilationShown,
  toggleCompilationSelection,
  searchInCompilation,
  getSearchCompilation
} = useSearchRecommendationCompilation(
  MAX_ITEMS_DESKTOP,
  MAX_ITEMS_MOBILE,
  searchIsFailed,
  filters,
  onSearch
)
onMounted(() => getSearchCompilation('popular'))

//mobile
/**
 * заголовок для мобильной версии
 */
const selectedCategoryHeader = computed(() => {
  if (isCompilationSelected.value) return compilation.value?.title
  if (isCategorySelected.value) return searchResultsByCategory.value?.name

  return undefined
})

const resultsBlock = useTemplateRef<HTMLElement>('resultsBlock')
/**
 * обрабатывает скролл блока для переключения щапки
 */
const smallHeader = ref(false)
const MIN_SCROLL = 300
const onResultsScroll = throttle(function () {
  if (!resultsBlock.value || isMdAndUp.value) return

  const { scrollTop } = resultsBlock.value

  smallHeader.value =
    (isCompilationSelected.value || isCategorySelected.value) && scrollTop >= MIN_SCROLL
}, 300)

onBeforeUnmount(() => {
  resetSearch()
  resetSearchResultsByCategory()
})

onBeforeRouteLeave(({ name: fromName }, { name: toName }) => {
  if (fromName !== toName) emit('close')
})
onBeforeRouteUpdate(({ params: toParams }, { params: fromParams }) => {
  const newParam = !isEqual(toParams, fromParams)

  if (newParam) emit('close')
})
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .input {
    animation: input 0.2s forwards ease-out;
    will-change: transform;
  }

  .gradient {
    will-change: opacity;
    animation: gradient 0.2s forwards 0.25s ease-out;
  }

  .content {
    will-change: transform;
    animation: content 0.2s forwards 0.3s ease-out;
  }

  .container-block {
    will-change: transform;
    animation: container 0.2s forwards 0.3s ease-out;
  }
}

@keyframes input {
  to {
    width: 100%;
    transform: none;
  }
}

@keyframes gradient {
  to {
    opacity: 1;
  }
}

@keyframes content {
  from {
    transform: translateY(-50vh);
  }

  to {
    transform: none;
  }
}

@keyframes container {
  from {
    height: 58px;
  }

  to {
    height: 100%;
  }
}
</style>
