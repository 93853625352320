import { storeToRefs } from 'pinia'
import { computed, type Ref } from 'vue'
import type { BaseSearchFilters } from '@/6_shared/model'
import { type SearchItem, useSearch } from '../../model'

export const useSearchCategory = (
  filters: Ref<BaseSearchFilters>,
  searchWrapper: (handler: () => Promise<void>, needLoading?: boolean) => Promise<void>,
  handler: () => void
) => {
  const { searchVenues, searchEvents, resetSearchResultsByCategory } = useSearch()
  const { searchResultsCategoryPagination } = storeToRefs(useSearch())

  const isCategorySelected = computed<boolean>(
    () => !!(filters.value.filter?.categoryIds && filters.value.filter?.categoryIds.length)
  )
  const isVenueCategorySelected = computed(() => {
    if (!isCategorySelected.value) return false

    return filters.value.filter?.categoryIds![0] < 0
  })
  /**
   * ищет внтури выбранной категории
   */
  const searchInCategory = async () => {
    await searchWrapper(async () => {
      if (!isCategorySelected.value) return

      const [id] = filters.value.filter!.categoryIds!

      if (id < 0) await searchVenues(filters.value)
      else await searchEvents(filters.value)
    })
  }

  /**
   * загружает следующую страницу для поиска внтури категории
   */
  const loadNextCategoryPage = async () => {
    await searchWrapper(async () => {
      if (!isCategorySelected.value) return

      const [id] = filters.value.filter!.categoryIds!
      const currentPagination = searchResultsCategoryPagination.value || filters.value.pagination
      const newPagination = {
        ...currentPagination,
        currentPage: currentPagination.currentPage + 1
      }
      const newFilters = {
        ...filters.value,
        pagination: newPagination
      }

      if (id < 0) await searchVenues(newFilters, true)
      else await searchEvents(newFilters, true)
    }, false)
  }

  /**
   * обрабатывает клик по категории
   * @param categoryId
   */
  const toggleCategorySelection = (categoryId?: SearchItem['id']) => {
    const deleteCategory = () => {
      delete filters.value.filter.categoryIds
      resetSearchResultsByCategory()
    }

    if (
      !categoryId ||
      (isCategorySelected.value && filters.value.filter!.categoryIds!.includes(categoryId))
    ) {
      // уже выбрана. убираем
      deleteCategory()
    } else {
      // ставим выбранную
      filters.value.filter.categoryIds = [categoryId]
    }

    handler()
  }

  return {
    isCategorySelected,
    isVenueCategorySelected,
    searchResultsCategoryPagination,
    searchInCategory,
    loadNextCategoryPage,
    toggleCategorySelection
  }
}
