<template>
  <div class="pb-7 md:pb-9">
    <SearchResultCategoryHeader
      v-if="value && ((isMdAndDown && !open) || isMdAndUp)"
      :value="value.title"
      :open="open"
      :is-big="isBig"
      class="mb-2"
      @toggle="$emit('toggle')"
    />
    <CompilationWidget
      :loading="loading"
      :size="isMdAndUp ? 'small' : 'big'"
      :value="value"
      type="list"
      :show-all="open"
      hide-header
    >
      <template #empty>
        <EmptySearch />
      </template>

      <template v-if="!open" #preview>
        <UiSearchResultPreview
          v-if="previewItems"
          :value="previewItems"
          href=""
          :size="isMdAndUp ? 'small' : 'big'"
          @click="$emit('toggle')"
        />
      </template>
    </CompilationWidget>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { CompilationWidget } from '@/3_widgets/Compilation'
import type { Compilation } from '@/5_entities/Compilation'
import { SearchResultCategoryHeader } from '@/5_entities/Search'
import { useBreakpoint } from '@/6_shared/lib'
import { UiSearchResultPreview } from '@/6_shared/ui'
import EmptySearch from './EmptySearch.vue'

type PropType = {
  value?: Compilation
  loading?: boolean
  open: boolean
  isBig: boolean
  maxItems: number
}
type EmitType = {
  (e: 'toggle'): void
}

const props = withDefaults(defineProps<PropType>(), {
  value: undefined,
  loading: false,
  isBig: false
})
defineEmits<EmitType>()

const {
  md: [isMdAndUp, isMdAndDown]
} = useBreakpoint()

const previewItems = computed(() => {
  if (isMdAndUp.value || !props.value || props.value?.items?.length <= props.maxItems) return

  return props.value.items.slice(-3)
})
</script>
