import isEmpty from 'lodash/isEmpty.js'
import { storeToRefs } from 'pinia'
import { computed, type Ref, ref } from 'vue'
import { useBreakpoint } from '@/6_shared/lib'
import { type BaseSearchFilters, SearchSorting } from '@/6_shared/model'
import { useSearch } from '../search.store.ts'

export const useSearchRecommendationCompilation = (
  maxItemsDesktop: number,
  maxItemsMobile: number,
  searchIsFailed: Ref<boolean>,
  filters: Ref<BaseSearchFilters>,
  handler: () => void
) => {
  const {
    md: [isMdAndUp]
  } = useBreakpoint()
  const { getSearchCompilation } = useSearch()
  const { searchCompilation, compilationInitialized } = storeToRefs(useSearch())

  const isCompilationSelected = ref(false)

  const maxItems = computed(() => (isMdAndUp.value ? maxItemsDesktop : maxItemsMobile))
  const isCompilationBig = computed(
    () => !!searchCompilation.value && searchCompilation.value.items.length > maxItems.value
  )
  /**
   * когда отоброжать подборку
   */
  const isCompilationShown = computed(() => {
    const noFilters =
      isEmpty(filters.value.filter) &&
      (!filters.value.sorting || filters.value.sorting === SearchSorting.popular)
    const compilationHasResults = !!searchCompilation.value?.items?.length
    const compilationReady =
      (compilationInitialized.value && compilationHasResults) ||
      (compilationInitialized.value && !noFilters && !compilationHasResults)

    if (isCompilationSelected.value) return true

    return !searchIsFailed.value && (isCompilationSelected.value || noFilters) && compilationReady
  })

  /**
   * меняет режим просмотра полной подборки
   */
  const toggleCompilationSelection = () => {
    if (!isCompilationSelected.value && !isCompilationBig.value) return
    isCompilationSelected.value = !isCompilationSelected.value

    if (!isCompilationShown.value) handler()
  }

  /**
   * ищет в подборке по фильтрам
   */
  const searchInCompilation = async () => {
    await getSearchCompilation('popular', filters.value)
  }

  return {
    compilation: searchCompilation,
    compilationInitialized,
    isCompilationSelected,
    isCompilationBig,
    isCompilationShown,
    toggleCompilationSelection,
    searchInCompilation,
    getSearchCompilation
  }
}
